@import '../../../Styles/global-styles.scss';

.formation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $secondary-color;
  color: $tertiary-color;

  .formation-list {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 95%;

    @media (max-width: $mobile-breakpoints) {
      flex-direction: column;
      align-items: center;
    }

    .formation {
      width: 50%;
      border: 2px solid black;
      background: #e5e7eae6;
      padding: 10px;
      box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
        rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
        rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

      @media (max-width: $mobile-breakpoints) {
        width: 85%;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 5px;

        @media (max-width: $mobile-breakpoints) {
          padding: 10px;
          font-size: $font-size-text;
        }
      }
    }

    .formation-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 160px;
      margin-bottom: 50px;

      @media (max-width: $tablets-breakpoints) {
        height: 250px;
        margin-bottom: 0px;
      }
      @media (max-width: $mobile-breakpoints) {
        height: 160px;
        margin-bottom: -20px;
      }

      .formation-date {
        font-style: italic;
      }
      .formation-name {
        text-align: center;
        font-size: $font-size-title;
        color: $primary-color;

        @media (max-width: $mobile-breakpoints) {
          font-size: 14px;
        }
      }
      .formation-location {
        font-style: italic;
        text-align: center;
        @media (max-width: $mobile-breakpoints) {
          font-size: 14px;
        }
      }
    }

    .bold {
      font-weight: bold;
    }

    .formation-description {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 40%;
    }
  }

  .details-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    padding: 16px;
    border-radius: 30px;
    width: 550px;
    font-size: 24px;
    background: $tertiary-color;
    color: $secondary-color;
    margin-top: 30px;
    text-align: center;
    border: 2px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;

    @media (max-width: $tablets-breakpoints) {
      font-size: 16px;
      width: 400px;
    }
    @media (max-width: $mobile-breakpoints) {
      font-size: 14px;
      width: 300px;
    }

    &:hover {
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 5px,
        rgba(0, 0, 0, 0.22) 0px 14px 14px;
      border: 2px solid $other-color;
    }
  }

  .btn-selected {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 5px,
      rgba(0, 0, 0, 0.22) 0px 14px 14px;
    border: 2px solid $other-color;
  }

  .arrow-active {
    transition: all 250ms;
    transform: rotate(180deg);
  }
  .arrow-deactive {
    transition: all 250ms;
    transform: rotate(0deg);
  }
}

.formation-details-container {
  display: flex;
  flex-direction: column;
  padding: 50px;
  gap: 30px;
}

.formation-active {
  transition: all 500ms;
  height: 0;
  opacity: 0;
}

.formation-deactive {
  transition: all 1s;
  height: auto;
  opacity: 1;
}
