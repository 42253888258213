@import '../../../Styles/global-styles.scss';

header {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  padding: 10px 30px 10px 30px;
  font-size: $font-size-title;
  color: $primary-color;
  background-color: $secondary-color;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);

  @media (max-width: 1320px) {
    font-size: 16px;
  }
  @media (max-width: $tablets-breakpoints) {
    font-size: $font-size-text;
    justify-content: space-between;
  }
  @media (max-width: $mobile-breakpoints) {
    font-size: 12px;
  }

  .header-title {
    font-weight: bold;
    font-size: 24px;
    font-style: italic;

    @media (max-width: 1320px) {
      font-size: 16px;
    }
    @media (max-width: 580px) {
      font-size: 14px;
    }
  }

  nav {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 50%;
    gap: 11px;

    @media (max-width: $laptop-breakpoints) {
      width: 75%;
    }
    @media (max-width: $tablets-breakpoints) {
      width: 80%;
    }
    @media (max-width: 580px) {
      width: 83%;
    }

    div {
      transition: all 250ms ease;
      &:hover {
        cursor: pointer;
        color: blue;
        font-style: italic;
      }
    }
  }

  .selected {
    text-decoration: underline;
  }
}
