@import '../../../Styles/global-styles.scss';

footer {
  background: $tertiary-color;
  color: $secondary-color;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;

  .copyright {
    display: flex;
    align-items: center;
    font-size: $font-size-text;

    @media (max-width: $mobile-breakpoints) {
      font-size: 12px;
    }
  }

  .contact-container {
    display: flex;
    justify-content: center;
    gap: 30px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
        object-fit: contain;
      }

      a {
        text-decoration: none;
        font-size: $font-size-text;
        color: $secondary-color;

        @media (max-width: $mobile-breakpoints) {
          font-size: 12px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
