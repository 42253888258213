@import '../../../Styles/global-styles.scss';

@keyframes modal-appear {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.modal-apparition {
  animation: modal-appear 0.35s ease forwards;
}

.modal-project {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 999;
  width: 40%;
  height: auto;
  max-height: 90%;
  overflow: scroll;
  border: 2px solid black;
  background: $secondary-color;
  color: $tertiary-color;
  left: 50%;
  top: 50%;
  padding: 5px;

  @media (max-width: $tablets-breakpoints) {
    width: 300px;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .modal-project-close {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border: 2px solid black;
      font-weight: bold;
      transition: all 250ms;
      font-size: $font-size-title;

      &:hover {
        cursor: pointer;
        background-color: black;
        color: white;
      }
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: scroll;

    .modal-github-link {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      color: $tertiary-color;
      font-size: $font-size-title;
      text-decoration: none;
      width: 100%;

      &:hover {
        text-decoration: underline;
      }

      img {
        width: 24px;
        height: 24px;
      }
    }

    .modal-image {
      width: 100%;
      height: 400px;
      object-fit: contain;

      @media (max-width: $mobile-breakpoints) {
        height: 200px;
      }
    }

    .modal-tech-image {
      width: 48px;
      height: 48px;
      object-fit: contain;
      @media (max-width: $mobile-breakpoints) {
        width: 32px;
        height: 32px;
      }
    }

    .modal-informations {
      font-size: $font-size-text;
      padding: 10px;
    }

    .modal-techno {
      display: flex;
      justify-content: space-evenly;
      align-self: center;
      width: 65%;
      margin: 10px 0 10px 0;
    }
  }
}
