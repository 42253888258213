//Variables
$primary-color: #0c2d48;
$secondary-color: white;
$tertiary-color: #050a30;
$other-color: red;

$text-color-primary: white;
$text-color-secondary: #0c2d48;

$font-size-main: 90px;
$font-size-big-title: 60px;
$font-size-title: 22px;
$font-size-text: 14px;

$breakpoints-1200: 1200px;
$laptop-breakpoints: 1024px;
$tablets-breakpoints: 768px;
$mobile-breakpoints: 480px;

h1,
h2 {
  font-size: $font-size-main;

  @media (max-width: $tablets-breakpoints) {
    font-size: $font-size-big-title;
  }
}

h4 {
  display: flex;
  justify-content: center;
  font-size: $font-size-title;
  margin: 0;
}

.section-title {
  border-bottom: 1px solid $tertiary-color;
  width: 50%;
  padding-bottom: 30px;
  text-align: center;

  @media (max-width: 1450px) {
    width: 75%;
  }

  @media (max-width: $mobile-breakpoints) {
    width: 100%;
    font-size: 28px;
  }
}

.-padding-bot-40 {
  padding-bottom: 40px;
}
