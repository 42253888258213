@import '../Styles/global-styles.scss';

@keyframes typing {
  0%,
  100% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  80% {
    width: 100%;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes return-arrow {
  0%,
  100% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(3px);
  }
}

.animated-text::after {
  content: '|';
  display: inline-block;
  animation: blink 0.8s infinite;
}

.animated-text {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  content: '';
  animation: typing 5s steps(30, end) infinite;
  text-decoration: underline;
}

main {
  display: flex;
  flex-direction: column;

  .hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 56px);
    overflow: hidden;
    background-image: url('../Assets/background.webp');
    background-attachment: fixed;
    background-position: center;
    background-size: cover;

    &-container {
      display: flex;
      align-items: center;
      width: 90%;

      @media (max-width: $laptop-breakpoints) {
        flex-direction: column;
      }
    }

    .introduction-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border: 6px solid $tertiary-color;
      border-radius: 20px;
      width: 50%;
      height: 80%;
      background-color: $primary-color;
      color: $text-color-primary;
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.8);

      @media (max-width: $breakpoints-1200) {
        padding: 30px;
      }

      @media (max-width: $laptop-breakpoints) {
        width: 80%;
        margin-top: 100px;
      }

      @media (max-width: $mobile-breakpoints) {
        margin: 0;
      }

      h1 {
        display: flex;
        justify-content: flex-start;
        margin: 0;
        color: $text-color-primary;
        font-size: $font-size-main;

        @media (max-width: $breakpoints-1200) {
          font-size: $font-size-big-title;
        }

        @media (max-width: $mobile-breakpoints) {
          font-size: $font-size-big-title;
          text-align: center;
        }
      }

      h2 {
        width: 70%;
        display: flex;
        gap: 6px;
        font-size: 30px;

        @media (max-width: $breakpoints-1200) {
          width: 90%;
          font-size: $font-size-title;
        }

        @media (max-width: $mobile-breakpoints) {
          width: 100%;
          font-size: $font-size-title;
        }
      }

      p {
        display: flex;
        justify-content: flex-start;
        font-size: $font-size-title;
      }
    }

    img {
      width: 50%;
      margin: 20px;
      height: 80%;
      object-fit: cover;
      border: 6px solid $tertiary-color;
      border-radius: 20px;
      box-sizing: border-box;
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.8);
    }
  }
}

.return-to-top {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 40px;
  right: 40px;
  z-index: 1000;

  border: none;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: white;
  border-radius: 35px;
  transition: all 0.3s ease;

  @media (max-width: $laptop-breakpoints) {
    bottom: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
  }

  i {
    font-size: 24px;
    animation: return-arrow 1.8s ease-in-out infinite;
    @media (max-width: $laptop-breakpoints) {
      font-size: 12px;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: #0056b3;
    transform: scale(1.3);
  }
}
