@import '../../../Styles/global-styles.scss';

.presentation {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $primary-color;
  color: $text-color-primary;

  &-text {
    display: flex;
    flex-direction: column;
    gap: 30px;
    line-height: 45px;
    width: 35%;
    font-size: $font-size-title;
    padding: 20px;

    @media (max-width: $tablets-breakpoints) {
      font-size: $font-size-text;
      width: 85%;
    }

    span {
      font-size: $font-size-title;
      font-style: italic;
      font-weight: bold;

      @media (max-width: $mobile-breakpoints) {
        font-size: $font-size-text;
      }
    }

    p {
      text-align: center;
      margin: 0;
      @media (max-width: $mobile-breakpoints) {
        line-height: 24px;
      }
    }
  }

  &-tech {
    margin-top: 70px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 50%;
    font-size: $font-size-big-title;

    @media (max-width: $mobile-breakpoints) {
      margin-top: 30px;
      width: 90%;
    }

    h3 {
      font-size: $font-size-title;
      margin: 10px 0 0 10px;

      @media (max-width: $mobile-breakpoints) {
        font-size: $font-size-text;
      }
    }

    &-container {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      width: 100%;
      gap: 20px;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: bold;
        width: 50px;
        height: 80px;
        font-size: $font-size-text;

        img {
          width: 48px;
          height: 48px;
          object-fit: contain;
        }
      }
    }
  }
}
