@import '../../Styles/global-styles.scss';

.data-name {
  font-size: 16px;
  text-decoration: underline;
  font-weight: bold;
}

.language-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-size: $font-size-text;
  font-weight: bold;

  @media (max-width: $tablets-breakpoints) {
    font-size: 12px;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .lang-bar-container {
      width: 56%;
      height: 10px;
      background: $secondary-color;
      border: 1px solid black;
      border-radius: 5px;

      .lang-bar {
        background: $other-color;
        height: 10px;
        border-radius: 5px;
      }
    }
  }
}
